import React from 'react'

const HomeSection2 = () => {
  return (
    <>
      <div class="container px-4 py-5" id="featured-3">
        <h1 className='theme_heading_after'>OUR SPECIALIZED SERVICES</h1>
        <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div class="feature col">
            <div class="feature-icon theme_bg bg-gradient">
              
            </div>
            <h2>Freight brokerage</h2>
            <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
            <a href="tel:+1 (410) 564-9517" class="icon-link">
              Call Now
            </a>
          </div>
          <div class="feature col">
            <div class="feature-icon theme_bg bg-gradient">
              
            </div>
            <h2>Handled with Care</h2>
            <p>Attention to detail matters, coordinating Goods shipments, through our skilled employees, advanced software, and project management capabilities. Customer satisfaction guaranteed. </p>
            <a href="tel:+1 (410) 564-9517" class="icon-link">
              Call Now
              
            </a>
          </div>
          <div class="feature col">
            <div class="feature-icon theme_bg bg-gradient">
              
            </div>
            <h2>Phone services</h2>
            <p>Am Globel Network Solutions having a team of customer service specialist who help with field phone calls from customers with questions about a company's services and products.  </p>
            <a href="tel:+1 (410) 564-9517" class="icon-link">
              Call Now
              
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSection2
