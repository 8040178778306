import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import BannerSection from './BannerSection'
import HomeSection1 from './HomeSection1'
import HomeSection2 from './HomeSection2'
import HomeSection3 from './HomeSection3'
import Map from './Map'

const HomePage = () => {
  return (
    <>
      <Header />
      <BannerSection />
      <HomeSection1 />
      <HomeSection3 />
      <HomeSection2 />
      <Map />
      <Footer />
    </>
  )
}

export default HomePage
