import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const FaqPage = () => {
  return (
    <>
      <Header />
      <div className="p-5">
        <h1 className="theme_heading_after">FAQ</h1>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                class="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      The Big List Of Logistics And Supply Chain FAQs
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Logistics and supply chain is a massive subject! Right from inventory management to delivery automation to demand forecasting, there are tons of subjects. And it is almost humanly impossible for one person to keep track of all. In this article, we intend to populate a list of Frequently Asked Questions (FAQs) across subjects in a simple manner to have a cursory overview of the subject. If there’s a question you want to ask, just add them in the comments, and we’ll answer them along with adding it to the list.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      What is route optimization?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Route optimization is the process of calculating the most cost-efficient route to reach a destination. It merges factors like the number of orders, multi-stops, traffic congestion, selection of the nearest drivers, location, etc., improving the speed of delivery and reduction in the carbon footprint.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What is a delivery automation platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>
                        A delivery automation platform enables enterprises with enhanced functionalities and security that functions across the operations, development, and other teams.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      What is ePOD?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>ePOD stands for electronic proof of delivery. It helps streamline the documentation of your deliveries, eliminating paperwork and capturing digital signatures, and photos that will guarantee delivery (offering proof of delivery).</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      How is ETA calculated?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>ETA stands for estimated time for arrival. ETA is calculated based on a) Time to load orders in a trip and b) Travel time to reach the customer’s location.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSix">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      What is a real-time visibility platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>A real-time visibility platform allows operation managers and customers real-time insights into orders and shipments right from the supplier, warehouse, or store.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingSeven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      Which is the best logistics software?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>As business operations become more sophisticated, it’s important to effectively manage your transportation of goods and services. Some of the best logistics software include- LogiNext Mile, CAReGO, Fishbowl, Rose Rocket, Logistically TMS, and more.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEight">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      Is SaaS software better than on-premise?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Yes, SaaS software is better than on-premise as it offers better flexibility, reliability, security, and cost-effective solutions to manage your logistics operations. It helps track and manage your orders and shipments in the most robust way.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingNine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      What is auto allocation of orders?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Auto allocation is the process where your orders (on-demand deliveries) will be automatically allocated to the nearest delivery partner, reducing manual intervention, and leading to improved efficiency of your fleet.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      What is a DRS (delivery run sheet)?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>A delivery run sheet is a backup for proof of delivery. A courier/ delivery person will carry a receipt of delivery acceptance, wherein the customer has to sign/ provide name/ mobile number on receiving the order, which acts as proof of delivery.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingEleven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEleven"
                      aria-expanded="false"
                      aria-controls="flush-collapseEleven"
                    >
                      What is inventory tracking?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEleven"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingEleven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Inventory tracking is a process wherein you can track items from multiple warehouses, stores, and fulfillment centers. This is essential to meet customer demand and restock items in relevant locations.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwelve">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwelve"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwelve"
                    >
                      What is the importance of tracking inventory?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwelve"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwelve"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Today’s customers need fast shipping for orders, whether you place an order from eCommerce marketplaces, grocery stores, and other retail industries. There has been a rise in last-mile delivery suppliers that help with same-day or next-day deliveries. For such businesses, it’s important to track inventory, as it will help in faster order fulfillment, and efficient stock control (to avoid out-of-stock messaging).</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThirteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThirteen"
                      aria-expanded="false"
                      aria-controls="flush-collapseThirteen"
                    >
                      What is the best way to keep track of inventory?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThirteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThirteen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body text-start">
                      <p>Inventory tracking should be based on the number of times you count your inventory and the technology used. The two general types of tracking include-Full inventory counting- This tracking method uses staff members’ time to count the inventory and offers the most accurate results. (Note: You will have to stop your operations to get results.)Cycle counting- This tracking method helps keep track of your inventory; wherein the inventory is divided into sections and the entire inventory is gradually covered in the different cycles which allows you to keep running your operations 24/7 but will need a proper plan to ensure all the sections are covered on a timely basis.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FaqPage;
