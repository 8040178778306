import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import serviceImg from "../../../assets/imgs/service-detail1.jpeg"

const ServiceDetails = () => {
  return (
    <>
      <Header />
      <div className="p-5">
        <h1 className="theme_heading_after">OCEAN FREIGHT</h1>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={serviceImg} className="mb-3" />
              <p>Global Business Link, Inc. will ensure the delivery of your ocean shipment with freight you desire.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServiceDetails;
