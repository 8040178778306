import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const ServicePage = () => {
  return (
    <>
      <Header />
      <div className="pt-5">
        <h1 className="theme_heading_after">Our Services</h1>
        <div className="container mb-5">
          <p className="col-12 col-md-6 mx-auto">
            You need a Freight Forwarder dedicated to finding the lowest freight
            cost, the fastest transit time, and the most reliable carrier
            available. Because we take the time to learn everything we can about
            your business, you can trust that we’ll treat your shipments like
            they are our own.
          </p>
        </div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-3">
              <div className="card p-3">
                <p className="fs-2 mb-0">24/7 service</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card p-3">
                <p className="fs-2 mb-0">Pickup available in 5 cities</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card p-3">
                <p className="fs-2 mb-0">Small and large parcel cargo</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card p-3">
                <p className="fs-2 mb-0">24/7 Customer service</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5 ">
          <div className="row align-center">
            <div className="col-md-3">
              <div className="card p-3">
                <p className="fs-2 mb-0">Phone services</p>
              </div>
            </div>

          </div>
        </div>
        <div className="bg-light py-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 text-start">
                <h1 className="theme_heading_regular text-start">
                  Shipping FAQ
                </h1>
                <p>
                  Don’t see the topic you’re looking for? Check out our blog or
                  contact our team. We’re here to help.
                </p>
                <a href="/faq" className="btn btn-primary theme_btn btn-lg">Explore More</a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Do I Need Cargo Insurance? 
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>Without cargo insurance, importers have no real protection in the event of containers being damaged, lost, or subject to a general average. In these situations, carrier liability (if it even applies) is nowhere close to being enough to cover costs. Cargo lines typically offer rates per pound that amount to vastly less than the cargo’s actual worth.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        What Are Incoterms®?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>International Commercial terms (“Incoterms®” for short) are a set of three-letter standard trade terms used in international contracts for the sale of goods. Incoterms are widely used in international and domestic commercial transactions, and are essential to understand prior to a shipment. Which terms you and your seller/buyer agree upon will define the responsibilities each party has for the delivery of goods under sales contracts. </p>
                        <p>The Incoterms® Rules are protected by copyright owned by ICC.  Further information on the Incoterms® Rules may be obtained from the ICC website iccwbo.org.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        What is Denied Party Screening?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body text-start">
                        <p>Denied Party Screening is a process that is done for each of our exports to make sure we are compliant with export regulations. It consists of screening the details of the shipment against lists that the government has compiled of persons, organizations, and corporations that we and/or our customers should not be doing business with.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
