import React from 'react'
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import logo from "../assets/imgs/ama-logistics-services.png";

const Header = () => {
  return (
    <div>
      <header className="">
        <div className="container top-bar d-flex flex-row justify-content-between">
          <div className="">
            <a href="mailto:info@amalogisticsservices.com" className="">
              <FaEnvelope className="me-2 theme_color" />
              <span className="d-none d-md-inline-block text-dark small">info@amalogisticsservices.com</span>
            </a>
            <span className="mx-md-2 d-inline-block" />
            <a href="tel:+1 (410) 564-9517" className="">
              <FaPhoneAlt className="me-2 theme_color" />
              <span className="d-none d-md-inline-block text-dark small">+1 (410) 564-9517</span>
            </a>
          </div>
          <div>
            <a href="#" className="">
              <FaTwitter className="me-2 theme_color" />
              <span className="d-none d-md-inline-block text-dark small">Twitter</span>
            </a>
            <span className="mx-md-2 d-inline-block" />
            <a href="https://www.facebook.com/AmaLogisticsServices" className="">
              <FaFacebookF className="me-2 theme_color" />
              <span className="d-none d-md-inline-block text-dark small">Facebook</span>
            </a>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-dark theme_bg">
          <div className="container">
            <a className="navbar-brand fs-2 fw-bold" href="/">
              {/* Company Logo */}
              <img src={logo} height="60" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item me-3">
                  <a className="nav-link" href="/about">
                    About
                  </a>
                </li>
                <li className="nav-item me-3">
                  <a className="nav-link" href="/service">
                    Service
                  </a>
                </li>
                <li className="nav-item me-3">
                  <a className="nav-link" href="/faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Header
