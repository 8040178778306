import React from 'react'

const HomeSection2 = () => {
  return (
    <>
      <div class="container py-5" id="featured-3">
        <div className='w_50 mx-auto'>
          <h1 className='theme_heading_after'>INTERNATIONAL SUPPLY CHAIN SOLUTIONS</h1>
          <p className=''>Cargo Logistics Group provides a comprehensive range of global logistics solutions to our diverse customer base. We achieve success through forging close relationships with our customers, adapting quickly to industry changes, and providing excellent reporting.</p>
        </div>
      </div>
    </>
  )
}

export default HomeSection2
