import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const About = () => {
  return (
    <>
      <Header />
      <section className="about-section">
        <div className="container text-start">
          <div className="row py-5">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About Us</span>
                  <h2>We are Creative Tech Enthusiast working since 2015</h2>
                </div>
                <div className="text">
                  Ama Logistics Services has been providing a comprehensive
                  range of global logistics solutions to our diverse customer
                  base. We achieve success through forging close relationships
                  with our customers, adapting quickly to industry changes, and
                  providing an excellent level of responsiveness and reporting.
                </div>
                <h6 className="color-black">Dedicated Team: </h6>
                <div className="text">
                  Our staff members are knowledgeable, educated, and dedicated
                  to guiding you to the logistics solutions best for your
                  company. We have trainable logistics industry professionals,
                  that we also equip with the latest technology and tools they
                  need to provide you with the best possible customer
                  experience.
                </div>
                <div className="btn-box">
                  <a href="/contact" className="theme-btn btn-style-one">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <div className="author-desc">
                  <h2>Ama Logistics</h2>
                  <span>Services</span>
                </div>
                <figure className="image-1">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                    <img
                      title="I'm Admin"
                      src="https://i.ibb.co/QP6Nmpf/image-1-about.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
          <div className="sec-title mb-4">
            <h2>Uncompromising Accuracy, Integrity, & Quality</h2>
          </div>
          <div className="text">
            Attention to detail matters, coordinating Goods shipments, through
            our skilled employees, advanced software, and project management
            capabilities, Ama Logistics Services offers the same uncompromising
            accuracy, integrity, and quality to our customers.
          </div>
          <div className="sec-title mb-4">
            <h2>Accountability</h2>
          </div>
          <div className="text">
          Accountability is essential both in the workplace and with each of our customers. Our advanced reporting tools allow for flexible communication options and strict accountability. Minor errors can cause major delays, so each of our staff members has a developed eye for detail and a commitment to open communication.
          </div>
          <div className="sec-title mb-4">
            <h2>Our Mission</h2>
          </div>
          <div className="text">
          Ama Logistics Services seeks to partner with like-minded companies to continually meet the needs of our customers, the needs of our employees, and the demands of a constantly-shifting marketplace. We strive to cultivate a work environment made up of the best team in the market and employees who take pride in their company, their team, and our collective goal achievements.
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
