import React from 'react'
import serviceImg from "../../../assets/imgs/service-detail1.jpeg"

const HomeSection3 = () => {
  return (
    <div>
      <div className="container-fluid text-center">
        <div class="row g-4 py-3 row-cols-1 row-cols-lg-3">
          <div class="feature col container_foto position-relative bg-dark">
          <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
                View More
              </a>
            </div>
            <article className="text-left">
              <h2>
                SHIPMENT SERVICES
              </h2>
              <h4>Global Business Link, Inc. will insure that your moving job is convenient, relaxing, reliable, and fast.</h4>
            </article>
            <img
            src={serviceImg}
              // src="https://img-aws.ehowcdn.com/400x400/ds-img.studiod.com/Half_Dome_from_Glacier_Point0_1.jpg"
              alt=""
            />
          </div>
          <div class="feature col container_foto position-relative bg-dark">
            <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
                View More
              </a>
            </div>
            <article className="text-left">
              <h2>
                WAREHOUSE & STOGRAGE
              </h2>
              <h4>Global Business Link, Inc. provides storage for goods, vehicles, furniture, and other commodities.</h4>
            </article>
            <img
            src={serviceImg}
              // src="https://pbs.twimg.com/profile_images/781518570018648065/HcvZhTVn_400x400.jpg"
              alt=""
            />
          </div>
          <div class="feature col container_foto position-relative bg-dark">
            <div className="ver_mas text-center theme_bg d-flex justify-content-center">
              <a href="/service-detail" class="icon-link">
                View More
              </a>
            </div>
            <article className="text-left">
              <h2>
                CONTAINERS & RORO
              </h2>
              <h4>GBL provides shipping vehicles, SUV, trucks, and other types of transportation goods with either containers or RORO.</h4>
            </article>
            <img
            src={serviceImg}
            height={400}
              // src="https://img-aws.ehowcdn.com/400x400/ds-img.studiod.com/Half_Dome_from_Glacier_Point0_1.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection3
