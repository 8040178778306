import React from 'react'

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98859.33614233667!2d-76.86568532911436!3d39.25752807395616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c81f7c74c6818d%3A0xe05d25608515d983!2sDaniels%2C%20MD%2021043!5e0!3m2!1sen!2sus!4v1681291186776!5m2!1sen!2sus"
        width={"100%"}
        height={300}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  )
}

export default Map
