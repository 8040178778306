import React from 'react'
import AmaLogistics from "./../../../assets/imgs/Time-critical-cargo-delivery.mp4"

const BannerSection = () => {
  return (
    <>
      
        <div className='position-relative'>
        <video className='home_banner' width="100%" autoPlay muted loop>
          <source src={AmaLogistics} type="video/mp4" />
          {/* <source src={AmaLogistics} type="video/ogg" /> */}
        </video>
          <div class="position-absolute top-50 start-50 translate-middle text-white z-index">
              <h1 className='theme_main_heading'>Time Critical Delivery and Logistics</h1>
              <p className='theme_para'>From envelopes to pallets, Swift Delivery & Logistics is your one-stop, full-service same day delivery and logistics solution.</p>
              <a href='/contact' className='btn btn-primary theme_btn btn-lg'>Get a quote</a>
          </div>
        </div>
    </>
  )
}

export default BannerSection
