import React from 'react'
import logo from "../assets/imgs/ama-logistics-services.png";

const Footer = () => {
  return (
    <div>
      <footer className="footer-distributed">
        <div className="footer-left">
            <h3>
            {/* Company<span>logo</span> */}
            <img src={logo} height="60" />
            </h3>
            <p className="footer-links">
            <a href="/" className="link-1">
                Home
            </a>
            <a href="/about">About</a>
            <a href="/service">Service</a>
            <a href="/contact">Contact</a>
            </p>
            <p className="footer-company-name">Ama Logistics Services © 2023</p>
        </div>
        <div className="footer-center">
            <div>
            <i className="fa fa-map-marker" />
            <p>
            Ellicott City, MD 21043
            </p>
            </div>
            <div>
                <a href='tel:+1 (410) 564-9517'>
                    <i className="fa fa-phone" />
                    <p>+1 (410) 564-9517</p>
                </a>
               
            </div>
            <a href='tel:+1 (800)-279-1745'>
                    <p style={{alignSelf:'center',marginLeft:'15%'}}>+1 (800)-279-1745</p>
                </a>
            <div>
            <i className="fa fa-envelope" />
            <p>
                <a href="mailto:info@amalogisticsservices.com">info@amalogisticsservices.com</a>
            </p>
            </div>
        </div>
        <div className="footer-right">
            <p className="footer-company-about">
            <span>About the company</span>
            Ama Logistics Services has been providing a comprehensive range of global logistics solutions to our diverse customer base.
            </p>
            <div className="footer-icons">
            <a href="#">
                <i className="fa fa-facebook" />
            </a>
            <a href="#">
                <i className="fa fa-twitter" />
            </a>
            <a href="https://www.linkedin.com/company/amalogisticsservices/">
                <i className="fa fa-linkedin" />
            </a>
            <a href="#">
                <i className="fa fa-github" />
            </a>
            </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
